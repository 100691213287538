/**
 * retrieves pagination data from the response headers.
 * @param {Object} headers - the HTTP response headers.
 * @returns {Object} object containing pagination data.
 */
export const getPaginateData = (headers) => {
  const page = Number(headers["x-page"]);
  const total = Number(headers["x-total"]);
  const perPage = Number(headers["x-per-page"]);
  const pages = Math.ceil(total / perPage);

  return {
    page: page,
    pages: pages,
    total: total,
    perPage: perPage,
  };
};

/**
 * Extracts the ID from a string enclosed in square brackets within a specific filter,
 * and returns an updated object with the extracted value replacing the original filter value.
 *
 * @param {Object} params - The object containing various filters or query parameters.
 * @param {string} filterName - The key in `params` whose value contains the ID to extract.
 * @returns {Object} A new object where the specified filter's value is replaced by the extracted ID.
 * If the filter does not exist or is not formatted correctly, the original `params` object is returned.
 */
export const obteinIdInFormatedStringQuery = (params, filterName) => {
  if (params[filterName]) {
    const formatedId = params[filterName].substring(
      params[filterName].indexOf("[") + 1,
      params[filterName].indexOf("]")
    );
    return { ...params, [filterName]: formatedId };
  }

  return { ...params };
};
