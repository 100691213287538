import React, { createContext, useContext } from "react";

import { useQuery } from "react-query";

import { getUser } from "../../user/services/user.requests";
import { userLogout } from "../services/session.requests";

import { deleteLocalStorageValue } from "../../common/utils/localStorage.utils";
import {
  deleteCookieValue,
  getCookieValue,
} from "../../common/utils/cookie.utils";

import { User } from "../../../api/models";

import { SESSION_QUERY_KEYS } from "../constants/session.consts";

export const SessionContext = createContext(null);

//TODO: Cuando terminemos de migrar el usuario logueado de mobx a este context:
// 1 - Eliminar tood lo relacionado a store.
// 2 - Unificar los métodos de update que están en este contexto.

export const SessionContextProvider = (props) => {
  const { children, store } = props;

  const tokenStoraged = getCookieValue("sn_loggin_user_token");
  const userIdStoraged = getCookieValue("sn_loggin_user_id");

  const [loggedUser, setLoggedUser] = React.useState(null);

  //? open session.
  const { data, error, isLoading, isSuccess } = useQuery({
    enabled: !!tokenStoraged && !!userIdStoraged,
    queryKey: SESSION_QUERY_KEYS.loggedUser(),
    queryFn: () => {
      const params = {
        fields: "account.onboarding",
      };

      return getUser(userIdStoraged, params);
    },
  });

  React.useEffect(() => {
    if (error) {
      handleUserLogout();
    } else if (isSuccess && data) {
      data.results.token = tokenStoraged;
      handleLoginOfUser(data.results);
    }
  }, [data, error, isSuccess, store]); // eslint-disable-line

  const handleUserLogout = () => {
    userLogout();

    deleteCookieValue("sn_loggin_user_token");
    deleteCookieValue("sn_loggin_user_id");
    deleteLocalStorageValue("sn_salesforce_token");

    setLoggedUser(null);
    store.signOut();
  };

  const handleUpdateOfUser = (user) => {
    const userInstanced = new User(user);

    store.loggedInUser.avatar_url = user.avatar_url;
    store.loggedInUser.email = user.email;
    store.loggedInUser.name = user.name;
    store.loggedInUser.last_name = user.last_name;

    setLoggedUser(userInstanced);
  };

  const handleUpdateOnboarding = (account) => {
    const newUser = Object.assign({}, loggedUser, { account });
    const instancedUser = new User(newUser);

    store.loggedInUser.company.onboarding = account.onboarding;
    store.loggedInUser.company.picking_preference_own =
      account.picking_preference_own;

    setLoggedUser(instancedUser);
  };

  const handleLoginOfUser = (user) => {
    const userInstanced = new User(user);

    store.saveInfo(user);

    setLoggedUser(userInstanced);
  };

  const valuesObj = {
    handleLoginOfUser,
    handleUpdateOfUser,
    handleUpdateOnboarding,
    handleUserLogout,
    isLoading: isLoading || (isSuccess && !loggedUser),
    isLoggedIn: isSuccess && loggedUser,
    loggedUser,
  };

  return (
    <SessionContext.Provider value={valuesObj}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);
