import { observable, action, computed } from "mobx";

import { Store } from "../lib";
import { UISettings } from "../models";
import moment from "moment-timezone";

import {
  CANCELLED,
  RETURN_A_DESPACHAR_FILTER,
  RETURN_A_RETIRAR_FILTER,
  RETURN_DESPACHADA_FILTER,
  RETURN_DEVUELTA_FILTER,
  RETURN_EN_SUCURSAL_FILTER,
  NEW,
  RETURN_NO_DEVUELTA_FILTER,
  RETURN_NO_RECIBIDA_FILTER,
  RETURN_NO_RETIRADA_FILTER,
  RETURN_NO_REINGRESADA_A_STOCK_FILTER,
  RETURN_PROCESADA_FILTER,
  RETURN_RECIBIDA_FILTER,
  RETURN_REINGRESADA_A_STOCK_FILTER,
  RETURN_RETIRADA_FILTER,
} from "../assets/constants/exchangeConsts/orderExchangeStatus";

import {
  GOODSIN_CANCELLED,
  GOODSIN_COMPLETED,
  GOODSIN_CONFIRMED,
  GOODSIN_CREATED,
  GOODSIN_PROCESSING,
  GOODSIN_RECEIVED,
  GOODSIN_REQUESTED,
} from "../assets/constants/goodsIns";

export default class UIStore extends Store {
  model = UISettings;
  urlRoot = "UI";

  @observable lists = {
    isProcessing: false,
    selectedIndex: [],
    selectedKits: [],
    selectedOrders: [],
    selectedProducts: [],
    selectedVariants: [],
    selectedWarehouses: [],
    chargeTypes: [],
    checkboxSelectedAllKits: false,
    checkboxSelectedAllOrder: false,
    checkboxSelectedAllProducts: false,
    checkboxSelectedAllVariants: false,
    checkboxSelectedAllWarehouses: false,
    checkboxSelectedAllAccounts: false,
    allKitsSelected: false,
    allOrdersSeleted: false,
    allProductsSelected: false,
    allVariantsSelected: false,
    allWarehousesSelected: false,
    shippingServices: null,
    holidays: [],
    storeTypes: null,
    carriers: null,
    countries: null,
    crossSearchInput: "",
    crossSearchOrders: null,
    crossSearchProducts: null,
    crossSearchVariants: null,
    dispatchPatterns: [
      {
        key: "Todos",
        value: "all",
      },
      {
        key: "Andreani",
        value: "me_andreani",
      },
      {
        key: "Correo Argentino",
        value: "me_correoargentino",
      },
      {
        key: "Crossdocking / Ocasa",
        value: "me_cross_docking",
      },
      {
        key: "Motonorte",
        value: "me_motonorte",
      },
      {
        key: "Oca",
        value: "me_oca",
      },
      {
        key: "Urbano",
        value: "me_urbano",
      },
    ],
    postOffices: [
      {
        key: "Todas las sucursales disponibles",
        value: "all",
      },
      {
        key: "Oca",
        value: "oca",
      },
      {
        key: "Garbarino",
        value: "garbarino",
      },
      {
        key: "Andreani",
        value: "andreani",
      },
    ],
    orderServiceTypes: [
      {
        id: "orders--serviceTypeFilter-checkbox-deliveryPaP",
        key: "Envío a domicilio",
        value: "delivery_pap",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-deliveryPaS",
        key: "Entrega en correo",
        value: "delivery_pas",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-pickupWarehouse",
        key: "Entrega en depósito",
        value: "pickup_warehouse",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-deliveryPapSameDay",
        key: "Same day",
        value: "delivery_pap_same_day",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-shipnowWarehouses",
        key: "Entrega en tienda",
        value: "shipnow_warehouses",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-shipnowFlex",
        key: "Envío flex",
        value: "shipnow_flex",
      },
    ],
    returnServiceTypes: [
      {
        id: "orders--serviceTypeFilter-checkbox-exchangePaP",
        key: "Devolución a domicilio",
        value: "exchange_pap",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-exchangeSap",
        key: "Devolución a sucursal",
        value: "exchange_sap",
      },
      {
        id: "orders--serviceTypeFilter-checkbox-exchangeWarehouse",
        key: "Devolución en depósito",
        value: "exchange_warehouse",
      },
    ],
    exchangeStatuses: [
      {
        key: "Esperando pago",
        value: "awaiting_payment",
      },
      {
        key: "Esperando devolución",
        value: "awaiting_exchange",
      },
      {
        key: "Nuevo",
        value: "new",
      },
      {
        key: "Listo para preparar",
        value: "ready_to_pick",
      },
      {
        key: "En recolección",
        value: "pickup_requested",
      },
      {
        key: "En preparación",
        value: "picking_list",
      },
      {
        key: "Empaquetando",
        value: "packing_slip",
      },
      {
        key: "Listo para despachar",
        value: "ready_to_ship",
      },
      {
        key: "Despachado",
        value: "shipped",
      },
      {
        key: "Detenido",
        value: "on_hold",
      },
      {
        key: "Cancelado",
        value: "cancelled",
      },
      {
        key: "Entregado",
        value: "delivered",
      },
      {
        key: "No entregado",
        value: "not_delivered",
      },
      {
        key: "Procesado",
        value: RETURN_PROCESADA_FILTER,
      },
      {
        key: "Devuelto",
        value: RETURN_DEVUELTA_FILTER,
      },
      {
        key: "No devuelto",
        value: RETURN_NO_DEVUELTA_FILTER,
      },
      {
        key: "Reingresado a stock",
        value: RETURN_REINGRESADA_A_STOCK_FILTER,
      },
      {
        key: "No reingresado a stock",
        value: RETURN_NO_REINGRESADA_A_STOCK_FILTER,
      },
      {
        key: "A retirar",
        value: RETURN_A_RETIRAR_FILTER,
      },
      {
        key: "Retirada",
        value: RETURN_RETIRADA_FILTER,
      },
      {
        key: "No retirada",
        value: RETURN_NO_RETIRADA_FILTER,
      },
      {
        key: "En sucursal",
        value: RETURN_EN_SUCURSAL_FILTER,
      },
      {
        key: "Recibida",
        value: RETURN_RECIBIDA_FILTER,
      },
      {
        key: "No recibida",
        value: RETURN_NO_RECIBIDA_FILTER,
      },
      {
        key: "A despachar",
        value: RETURN_A_DESPACHAR_FILTER,
      },
      {
        key: "Despachada",
        value: RETURN_DESPACHADA_FILTER,
      },
    ],
    orderStatuses: [
      {
        key: "Esperando pago",
        value: "awaiting_payment",
      },
      {
        key: "Nuevo",
        value: "new",
      },
      {
        key: "Listo para preparar",
        value: "ready_to_pick",
      },
      {
        key: "En recolección",
        value: "pickup_requested",
      },
      {
        key: "En preparación",
        value: "picking_list",
      },
      {
        key: "Empaquetando",
        value: "packing_slip",
      },
      {
        key: "Listo para despachar",
        value: "ready_to_ship",
      },
      {
        key: "Despachado",
        value: "shipped",
      },
      {
        key: "Detenido",
        value: "on_hold",
      },
      {
        key: "Cancelado",
        value: "cancelled",
      },
      {
        key: "Entregado",
        value: "delivered",
      },
      {
        key: "No entregado",
        value: "not_delivered",
      },
      {
        key: "Procesado",
        value: RETURN_PROCESADA_FILTER,
      },
      {
        key: "Devuelto",
        value: RETURN_DEVUELTA_FILTER,
      },
      {
        key: "No devuelto",
        value: RETURN_NO_DEVUELTA_FILTER,
      },
      {
        key: "Reingresado a stock",
        value: RETURN_REINGRESADA_A_STOCK_FILTER,
      },
      {
        key: "No reingresado a stock",
        value: RETURN_NO_REINGRESADA_A_STOCK_FILTER,
      },
    ],
    returnStatuses: [
      {
        key: "Nuevo",
        value: NEW,
      },
      {
        key: "A retirar",
        value: RETURN_A_RETIRAR_FILTER,
      },
      {
        key: "Retirada",
        value: RETURN_RETIRADA_FILTER,
      },
      {
        key: "No retirada",
        value: RETURN_NO_RETIRADA_FILTER,
      },
      {
        key: "En sucursal",
        value: RETURN_EN_SUCURSAL_FILTER,
      },
      {
        key: "Recibida",
        value: RETURN_RECIBIDA_FILTER,
      },
      {
        key: "No recibida",
        value: RETURN_NO_RECIBIDA_FILTER,
      },
      {
        key: "Procesada",
        value: RETURN_PROCESADA_FILTER,
      },
      {
        key: "A despachar",
        value: RETURN_A_DESPACHAR_FILTER,
      },
      {
        key: "Despachada",
        value: RETURN_DESPACHADA_FILTER,
      },
      {
        key: "Cancelada",
        value: CANCELLED,
      },
      {
        key: "Devuelta",
        value: RETURN_DEVUELTA_FILTER,
      },
      {
        key: "No devuelta",
        value: RETURN_NO_DEVUELTA_FILTER,
      },
      {
        key: "Reingresada a stock",
        value: RETURN_REINGRESADA_A_STOCK_FILTER,
      },
      {
        key: "No reingresada a stock",
        value: RETURN_NO_REINGRESADA_A_STOCK_FILTER,
      },
    ],
    returnsGroupStatuses: [
      {
        key: "Nuevo",
        value: "new",
      },
      {
        key: "Recolección asignada",
        value: "assigned_pickup",
      },
      {
        key: "A despachar",
        value: "ready_to_ship",
      },
      {
        key: "Despachado",
        value: "shipped",
      },
      {
        key: "Devuelto",
        value: "returned",
      },
      {
        key: "No devuelto",
        value: "not_returned",
      },
    ],
    userStatuses: [
      {
        key: "Inactivo",
        value: "false",
      },
      {
        key: "Activo",
        value: "true",
      },
    ],
    conditions: [
      {
        key: "Requieren atención",
        value: "require_attention",
      },
      {
        key: "En curso",
        value: "oncourse",
      },
      {
        key: "Preparado",
        value: "prepared",
      },
      {
        key: "En transito",
        value: "ontransit",
      },
      {
        key: "Sin agregar a una recolección",
        value: "require_pickup",
      },
    ],
    pickingPreferences: [
      {
        key: "Deposito",
        value: "warehouse",
      },
      {
        key: "Local propio",
        value: "cross_docking",
      },
      {
        key: "Mixto",
        value: "mixed",
      },
    ],
    argentinaStates: [
      "Ciudad Autónoma de Buenos Aires",
      "Buenos Aires",
      "Catamarca",
      "Chaco",
      "Chubut",
      "Córdoba",
      "Corrientes",
      "Entre Ríos",
      "Formosa",
      "Jujuy",
      "La Pampa",
      "La Rioja",
      "Mendoza",
      "Misiones",
      "Neuquén",
      "Río Negro",
      "Salta",
      "San Juan",
      "San Luis",
      "Santa Cruz",
      "Santa Fe",
      "Santiago del Estero",
      "Tierra del Fuego",
      "Tucumán",
    ],
    currencies: [
      {
        key: "ARS",
        value: "ARS",
      },
      {
        key: "USD",
        value: "USD",
      },
    ],
    goodsInsStatuses: [
      {
        key: "Creado",
        value: GOODSIN_CREATED,
      },
      {
        key: "Solicitado",
        value: GOODSIN_REQUESTED,
      },
      {
        key: "Confirmado",
        value: GOODSIN_CONFIRMED,
      },
      {
        key: "Recibido",
        value: GOODSIN_RECEIVED,
      },
      {
        key: "Procesándose",
        value: GOODSIN_PROCESSING,
      },
      {
        key: "Completado",
        value: GOODSIN_COMPLETED,
      },
      {
        key: "Cancelado",
        value: GOODSIN_CANCELLED,
      },
    ],
    packageTypes: [
      {
        key: {
          id: 100,
          height: 10,
          length: 25.6,
          width: 16,
        },
        value: "Caja A0",
      },
      {
        key: {
          id: 101,
          height: 11,
          length: 28.6,
          width: 25.6,
        },
        value: "Caja A1",
      },
      {
        key: {
          id: 102,
          height: 17,
          length: 38.6,
          width: 30.6,
        },
        value: "Caja A2",
      },
      {
        key: {
          id: 103,
          height: 26,
          length: 65.6,
          width: 40.6,
        },
        value: "Caja A3",
      },
      {
        key: {
          id: 103,
          height: 26,
          length: 65.6,
          width: 40.6,
        },
        value: "Caja A3",
      },
      {
        key: {
          id: 1025,
          height: 8.5,
          length: 38.6,
          width: 30.6,
        },
        value: "Caja A2 Intermedia",
      },
    ],
    fiscal_categories: [
      {
        key: "Monotributista",
        value: "MONO",
      },
      {
        key: "Monotributista",
        value: "MO",
      },
      {
        key: "Del exterior",
        value: "DEX",
      },
      {
        key: "Exento",
        value: "EX",
      },
      {
        key: "No computable IVA",
        value: "NOCOMPUTABLEIVA",
      },
      {
        key: "Responsable inscripto",
        value: "RI",
      },
      {
        key: "No Responsable inscripto",
        value: "NRI",
      },
      {
        key: "FCE Responsable inscripto",
        value: "FCEGRANRI",
      },
    ],
    storesStatuses: [
      {
        key: "Activo",
        value: "active",
      },
      {
        key: "Inactivo",
        value: "inactive",
      },
      {
        key: "Desautorizado",
        value: "unauthorized",
      },
      {
        key: "Pendiente de activación",
        value: "created",
      },
    ],
  };

  storeConfirmationText = {
    deactivate:
      "Los nuevos pedidos que ingresen a tu tienda dejarán de sincronizarse con shipnow.",

    activate:
      "Los nuevos pedidos que ingresen a tu tienda comenzarán a sincronizarse con shipnow.",

    authorize:
      "Los nuevos pedidos que ingresen a tu tienda comenzarán a sincronizarse con shipnow.",

    import:
      "Al activar tu tienda, podemos importar pedidos anteriores. No vamos a preparar ni enviar ninguno de estos pedidos hasta que vos lo pidas, pero vamos a crearlos para que te ahorres el trabajo.",

    introduction:
      "Cuando te conectás o integrás a una tienda online, empezamos a recibir información de tus ventas y productos, para así saber cuando hacer un envío, qué envíar, a quién y dónde.",

    api:
      "Si usas una plataforma que no soportamos todavía, o tu tienda es un desarrollo custom, te podes conectar usando nuesta API. Si vos no tenes idea que es una API, vas a tener que hablar con tu programador amigo. Pasale nuestra documentación, es re facil y podes hacer lo que quieras.",
  };

  goodsInConfirmationTexts = {
    confirm:
      "Le confirmaremos al vendedor que pasaremos a retirar sus pedidos. Para evitar malos entendidos es muy importante que estés seguro que los mismos se pasarán a recoger el día que él lo solicitó.",
    cancel:
      "No hay vuelta atrás de esta acción, y no vas a poder volver a editarla. Sólo deberías eliminar esta solicitud si estás completamente seguro de ello.",
    schedule:
      "Le confirmaremos al vendedor que la mercadería se ingresará en fecha distinta a la ya informada. Para evitar malos entendidos es muy importante que estés seguro que la nueva fecha será cuando realmente ingresemos la mercadería.",
    receive:
      "Le confirmaremos al vendedor que la mercadería ya llegó a nuestro depósito y la fecha en que será procesada. Para evitar malos entendidos es muy importante que estés seguro que la misma corresponde a este ingreso, ya arribó a shipnow y la fecha es la correcta.",
    complete:
      "Este movimiento se va a marcar como completado, verificá que todos los pedidos o productos hayan sido recibidos correctamente.",
  };

  confirmationTexts = {
    reanudar:
      "Al reanudarlo, vamos a intentar continuar con el proceso para enviarle los productos a tu cliente. Si la razón por la que se pausó originalmente tuvo que ver con falta de stock o información, quizá se vuelva a pausar hasta que el problema se resuelva. Si vos pausaste este pedido manualmente, eso no debería pasar.",
  };

  storeShippingRules = {
    condition: [
      { value: "Empieza con", id: "starts_with", visible: true },
      { value: "Contiene", id: "contains", visible: true },
      { value: "No Contiene", id: "not_contains", visible: true },
      { value: "Termina con", id: "ends_with", visible: true },
      { value: "Es igual a", id: "is", visible: true },
    ],
    action: [
      { value: "Enviar a domicilio", id: "ship_to_address", visible: true },
      {
        value: "Enviar a domicilio same day",
        id: "ship_to_address_express",
        visible: true,
      },
      { value: "Enviar a local", id: "shipnow_warehouses", visible: false },
      { value: "Enviar a depósito", id: "ship_to_warehouse", visible: true },
      { value: "Retirar por shipnow", id: "pickup_warehouse", visible: true },
      { value: "No preparar", id: "custom_operation", visible: true },
      { value: "Retirar por local", id: "pickup_store", visible: true },
      {
        value: "Enviar a Sucursal de Correo",
        id: "ship_to_post_office",
        visible: true,
      },
    ],
  };

  @observable
  routes = {
    "/app": "Inicio",
  };

  @observable newsCounter = 0;

  @action
  registerRoute(route, label) {
    this.routes[route] = label;
  }

  define(list, value) {
    const found = this.lists[list].find((x) => x.value === value);

    return found ? found.key : value;
  }

  @action
  clearSelectedKits() {
    this.lists.selectedKits = [];
    this.lists.checkboxSelectedAllKits = false;
    this.lists.allKitsSelected = false;
  }

  @action
  clearSelectedOrders() {
    this.lists.selectedOrders = [];
    this.lists.selectedIndex = [];
    this.lists.checkboxSelectedAllOrder = false;
    this.lists.allOrdersSeleted = false;
  }

  @action
  clearSelectedProducts() {
    this.lists.selectedProducts = [];
    this.lists.checkboxSelectedAllProducts = false;
    this.lists.allProductsSelected = false;
  }

  @action
  clearSelectedVariants() {
    this.lists.selectedVariants = [];
    this.lists.checkboxSelectedAllVariants = false;
    this.lists.allVariantsSelected = false;
  }

  @action
  clearSelectedWarehouses() {
    this.lists.selectedWarehouses = [];
    this.lists.checkboxSelectedAllWarehouses = false;
    this.lists.allWarehousesSelected = false;
  }

  get settings() {
    if (this.appStore.loggedInUser) {
      return this.get(this.appStore.loggedInUser.id);
    }

    return new UISettings({}, this);
  }

  @computed
  get hidePublications() {
    const userStoreTypes = this.appStore.loggedInUser.company.storeTypes;

    return userStoreTypes.filter((s) => s.isSupportingPublications) === 0;
  }

  workingDaysAhead(startDate, daysAhead) {
    let resultDate = moment(startDate);

    for (let i = 0; i < daysAhead; i++) {
      resultDate = this.getNextWorkingDay(resultDate);
    }

    return resultDate;
  }

  getHolidays() {
    const holidays = this.lists.holidays
      .filter((i) => i.type === "holiday")
      .map((holiday) => new Date(moment(holiday.date)));

    return holidays;
  }

  getNextWorkingDay(date) {
    const holidays = this.getHolidays();
    const mDate = moment(date).add(1, "days");

    //if date is saturday OR sunday
    if ([6, 7].includes(mDate.day())) {
      return this.getNextWorkingDay(mDate.add(1, "days"));
    }

    //Check if date is a holiday (verify day and month)
    holidays.forEach((holiday) => {
      if (mDate.isSame(holiday, "day") && mDate.isSame(holiday, "month")) {
        return this.getNextWorkingDay(mDate);
      }
    });

    return mDate;
  }

  afterSignIn() {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/track"
    ) {
      this.lists.shippingServices = this.appStore.shippingServices.getAll();
      this.lists.storeTypes = this.appStore.storeTypes.getAll();
      this.lists.chargeTypes = this.appStore.chargeTypes.getAll();
      this.lists.countries = this.appStore.countries.getAll();
      this.lists.carriers = this.appStore.carriers.getAll();

      this.appStore.coreAPIClient.search(`/holidays`, {}, true).then((res) => {
        this.lists.holidays = res.results;
      });
    }
  }

  printNewsCounter() {
    if (this.newsCounter > 0) {
      return `(${this.newsCounter})`;
    }

    return "";
  }

  setNewsCounter(counter) {
    this.newsCounter = counter;
  }
}
