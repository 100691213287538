import React from "react";

import { FAQLink } from "./FAQLink";

import { FAQS_CPS_RETURN } from "../../../assets/constants/links";

export const CPsReturnFAQ = () => {
  return (
    <FAQLink
      button={{
        href: FAQS_CPS_RETURN,
      }}
      margin="y-20"
      text="los códigos postales disponibles para devoluciones."
    />
  );
};
