import { version as webAppVersion } from "../../../../../package.json";

import {
  DOMAIN_BETA,
  DOMAIN_LOCALHOLST,
  DOMAIN_SHIPNOW_APP,
} from "../constants/settings.consts";

export const getShipnowHeader = () => {
  switch (window.location.hostname) {
    case DOMAIN_BETA:
      return `ShipnowMercurio/${webAppVersion}/beta/${process.env.REACT_APP_GIT_SHA}`;
    case DOMAIN_LOCALHOLST:
      return `ShipnowMercurio/${webAppVersion}/local`;
    case DOMAIN_SHIPNOW_APP:
      return `ShipnowMercurio/${webAppVersion}/prod/${process.env.REACT_APP_GIT_SHA}`;
    default:
      return "";
  }
};
