import { useEffect, useState } from "react";

import { SHOW_BANNER_TARIFF_RISE, WORDING_BANNER_TARIFF_RISE } from "./account";
import { notifyAccount } from "../../utils/notifications_accounts";

//TODO: Eliminar este archivo cuando se hagan las notificaciones al 100%.
export const useNotifications = (store) => {
  const account = store.loggedInUser?.company;

  const [arrayNotifications, setArrayNotifications] = useState(null);
  const [hasGodsIns, setHasGodsIns] = useState(null);
  const [hasOrders, setHasOrders] = useState(null);

  const isOperating = hasOrders && hasGodsIns;
  const isOnboarding = account?.isWaitingOnboarding();

  /* get orders stats */
  useEffect(() => {
    const isAdmin = store.loggedInUser?.isAdmin();

    if (!isAdmin && account) {
      const filters = {
        archived: true,
        seller_id: account.id,
        type: "variant",
      };

      store.orders
        .fetch("stats/v2", filters, {}, true)
        .andThen((res, error) => {
          if (!error) {
            setHasOrders(res.total.exists);
          }
        });
    }
  }, [account, store.loggedInUser, store.orders]);

  /* get goodsIn stats */
  useEffect(() => {
    const isAdmin = store.loggedInUser?.isAdmin();

    if (!isAdmin && account) {
      const filters = {
        archived: true,
        seller_id: account.id,
      };

      store.goodsIns.fetch("stats", filters, {}, true).andThen((res, error) => {
        if (!error) {
          setHasGodsIns(res.total.exists);
        }
      });
    }
  }, [account, store.goodsIns, store.loggedInUser]);

  /* notifications list */
  useEffect(() => {
    if (hasGodsIns !== null && hasOrders !== null) {
      setArrayNotifications([
        {
          category: "Novedades",
          id: "notifications--shipping-document",
          name: "shippingDocument",
          text:
            "Emítelo desde el detalle de la recolección tocando “Imprimir comprobante”. Entrégaselo al conductor cuando retire tus pedidos.",
          title: "Nuevo comprobante para recolecciones",
          type: "info",
          visible: true,
          push_at: new Date(2024, 11, 8, 17, 0, 0),
        },
        {
          category: "Novedades",
          id: "notifications--saas",
          name: "saasService",
          text:
            "Prepara pedidos en tu depósito con más eficiencia. Descubre Picknow y solicita una prueba gratis.",
          title: "¡Nueva herramienta!",
          type: "info",
          visible: notifyAccount(account.id),
          href: `https://www.shipnow.com.ar/picknow?company_name=${account.company_name}`,
          push_at: new Date(2024, 8, 25, 17, 0, 0),
        },
        {
          category: "Avisos",
          id: "notifications--warehouses-btn-news",
          name: "newWarehouse",
          text:
            "Mejoramos la creación y edición de depósitos, por eso necesitamos que ajustes tus datos.",
          title: "Actualiza la dirección de tus depósitos.",
          type: "warning",
          visible: true,
          push_at: new Date(2023, 7, 25, 12, 0, 0),
        },
        {
          category: "Nuestros servicios",
          id: "notifications--ourServices-btn-news",
          name: "news",
          text:
            "Conoce las novedades que sumamos en la plataforma y en el tracking.",
          title: "¡Mejoras en devoluciones, recolecciones y más!",
          type: "info",
          visible: true,
          href:
            "https://shipnow.notion.site/Seguimiento-en-devoluciones-gesti-n-de-recolecciones-y-m-s-d1ce4ca971cd4cd8bbbb80b3063fdbde?pvs=4",
          push_at: new Date(2023, 6, 28, 10, 0, 0),
        },
        {
          category: "Avisos",

          id: "notifications--advices-btn-new-tarrif",
          name: "newTarrif",
          text: WORDING_BANNER_TARIFF_RISE,
          title: "Actualizaremos nuestras tarifas",
          type: "info",
          visible: SHOW_BANNER_TARIFF_RISE,
          push_at: new Date(2023, 5, 23, 12, 0, 0),
        },
        {
          category: "Avisos",
          href:
            "https://shipnow.notion.site/Feriados-de-semana-santa-06f367331f9d441d840ef51f3fe5e51c",
          id: "notifications--advices-btn-holyWeek",
          name: "holyWeek",
          text:
            "Conoce cómo operaremos durante los feriados del 6, 7 y 8 de abril.",
          title: "Operación durante Semana Santa",
          type: "info",
          visible: false,
          push_at: new Date(2023, 3, 4, 9, 0, 0),
        },
        {
          category: "Ship tips",
          href:
            "https://shipnow.notion.site/Activaci-n-de-cuenta-d48fdffcc1fc47a8860289a8fa8076e1",
          id: "notifications--shipTips-btn-activateAccount",
          name: "activateAccount",
          text: "Lee nuestra guía y aprende cómo hacerlo.",
          title: "¡Aún no activaste tu cuenta!",
          type: "info",
          visible: isOnboarding,
          push_at: new Date(2023, 0, 5, 10, 45, 0),
        },
        {
          category: "Ship tips",
          href:
            "https://shipnow.notion.site/C-mo-empezar-a-operar-9d8295cc6115423889859c3745f4d4fe",
          id: "notifications--shipTips-btn-operationAccount",
          name: "operationAccount",
          text: "Conoce cómo dar tus primeros pasos.",
          title: "¡Ya puedes empezar a operar!",
          type: "info",
          visible: account?.active && !isOperating,
          push_at: new Date(2023, 0, 5, 10, 45, 0),
        },
        {
          category: "Ship tips",
          href:
            "https://shipnow.notion.site/C-mo-etiquetar-un-pedido-para-una-recolecci-n-aed3291366d24d2c9a22380fc53ca8f0",
          id: "notifications--shipTips-btn-prepareProductsCD",
          name: "prepareProductsCD",
          text: "En nuestra guía, te enseñamos cómo hacerlo.",
          title: "¿Sabes cómo preparar tus pedidos?",
          type: "info",
          visible: account?.isCrossdocking() && account?.active && !isOperating,
          push_at: new Date(2023, 0, 5, 10, 45, 0),
        },
        {
          category: "Ship tips",
          href:
            "https://shipnow.notion.site/C-mo-etiquetar-una-variante-para-un-ingreso-11278c77f8de480eb3ec3638552acf76",
          id: "notifications--shipTips-btn-prepareProductsFF",
          name: "prepareProductsFF",
          text: "En nuestra guía, te enseñamos cómo hacerlo.",
          title: "¿Sabes cómo preparar tus variantes?",
          type: "info",
          visible: account?.isFullfilment() && account.active && !isOperating,
          push_at: new Date(2023, 0, 5, 10, 45, 0),
        },
      ]);
    }
  }, [hasGodsIns, hasOrders]); // eslint-disable-line react-hooks/exhaustive-deps

  return arrayNotifications;
};
